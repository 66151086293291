import {
  FormattedMessage,
  injectIntl
} from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import { circleSmall, waveHorizontalLarge } from "../components/Images";
import Layout from "../components/Layout";
import PrivacyPolicy from "../partials/blue/PrivacyPolicy";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const Content = styled.div`
  padding-top: 120px;
  padding-bottom: 60px;
  background-size: 160px 17px, 160px 17px, 17px 17px, 17px 47px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge}),
    url(${circleSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 277px) top 90px,
    right calc(${theme.container.background} - 320px) top 150px,
    left calc(${theme.container.background} - 127px) bottom 18%;

  ${[theme.media.mobileMenuVisible]} {
    padding-top: 75px;
    padding-bottom: 30px;
    min-height: 0;
  }
`;

const Text = styled.div`
  h2 {
    font-size: 18px;
    margin-bottom: 0.35em;
  }
`;

const ContentPrivacyPolicy = styled.div`
  padding-bottom: 200px;
  ${[theme.media.maxM]} {
    padding-bottom: 100px;
  }
`;


class TermsPage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={intl.formatMessage({ id: "pages.privacyPolicy.title" })}
          meta={[
            {
              name: "description",
              content: intl.formatMessage({ id: "pages.privacyPolicy.description" })
            },
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.privacyPolicy.title" })
            },
            {
              property: "og:description",
              content: intl.formatMessage({ id: "pages.privacyPolicy.description" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <Content>
            <Container>
              <Text>
                <h1>
                  <FormattedMessage id="pages.privacyPolicy.title" />
                </h1>
                <p>
                  <FormattedMessage id="pages.privacyPolicy.description" />
                </p>
                <ContentPrivacyPolicy>
                  <Container>
                    <PrivacyPolicy />
                  </Container>
                </ContentPrivacyPolicy>
              </Text>
            </Container>
          </Content>
        </Layout>
      </>
    );
  }
}

export default injectIntl(TermsPage);
