import { FormattedHTMLMessage, FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
import styled from "../../styling/styled";

const AccordionItemHeadingCustom = styled(AccordionItemHeading)`
  padding-top: 30px;
  padding-bottom: 30px;
`;

const AccordionItemButtonCustom = styled(AccordionItemButton)`
  position: relative;
  padding-right: 35px;
  cursor: pointer;
  &::before {
    position: absolute;
    top: 10px;
    right: 0;
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    transition: transform 0.4s ease-in-out;
  }

  &[aria-expanded="true"]::before,
  &[aria-selected="true"]::before {
    transform: rotate(-135deg);
  }

  h4 {
    margin-bottom: 0;
  }
`;

const AccordionItemCustom = styled(AccordionItem)`
  border-bottom: 1px solid #e1e1e1;
`;

const AccordionItemPanelCustom = styled(AccordionItemPanel)`
  max-width: 783px;
  margin-left: 17px;
  padding-left: 25px;
  border-left: 1px solid #b5bdfb;
`;

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <>
        <Accordion allowZeroExpanded={true}>
          <AccordionItemCustom>
            <AccordionItemHeadingCustom>
              <AccordionItemButtonCustom>
                <h4>
                  <FormattedMessage id="partials.privacypolicy.item1.title" />
                </h4>
              </AccordionItemButtonCustom>
            </AccordionItemHeadingCustom>
            <AccordionItemPanelCustom>
              <h4>
                <FormattedMessage id="partials.privacypolicy.item1.part1.title" />
              </h4>
              <p>
                <FormattedMessage id="partials.privacypolicy.item1.part1.paragraph1" />
              </p>
              <p>
                <FormattedMessage id="partials.privacypolicy.item1.part1.paragraph2" />
              </p>
              <p>
                <FormattedMessage id="partials.privacypolicy.item1.part1.paragraph3" />
              </p>
              <h4>
                <FormattedMessage id="partials.privacypolicy.item1.part2.title" />
              </h4>
              <p>
                <FormattedMessage id="partials.privacypolicy.item1.part2.paragraph1" />
              </p>
            </AccordionItemPanelCustom>
          </AccordionItemCustom>
          <AccordionItemCustom>
            <AccordionItemHeadingCustom>
              <AccordionItemButtonCustom>
                <h4>
                  <FormattedMessage id="partials.privacypolicy.item2.title" />
                </h4>
              </AccordionItemButtonCustom>
            </AccordionItemHeadingCustom>
            <AccordionItemPanelCustom>
              <p>
                <FormattedMessage id="partials.privacypolicy.item2.part1.paragraph1" />
              </p>
            </AccordionItemPanelCustom>
          </AccordionItemCustom>
          <AccordionItemCustom>
            <AccordionItemHeadingCustom>
              <AccordionItemButtonCustom>
                <h4>
                  <FormattedMessage id="partials.privacypolicy.item3.title" />
                </h4>
              </AccordionItemButtonCustom>
            </AccordionItemHeadingCustom>
            <AccordionItemPanelCustom>
              <p>
                <FormattedMessage id="partials.privacypolicy.item3.paragraph1" />
              </p>
              <h4>
                <FormattedMessage id="partials.privacypolicy.item3.part1.title" />
              </h4>
              <ul>
                <li>
                  <FormattedMessage id="partials.privacypolicy.item3.part1.item1" />
                </li>
                <li>
                  <FormattedMessage id="partials.privacypolicy.item3.part1.item2" />
                </li>
                <li>
                  <FormattedMessage id="partials.privacypolicy.item3.part1.item3" />
                </li>
                <li>
                  <FormattedMessage id="partials.privacypolicy.item3.part1.item4" />
                </li>
              </ul>
            </AccordionItemPanelCustom>
          </AccordionItemCustom>
          <AccordionItemCustom>
            <AccordionItemHeadingCustom>
              <AccordionItemButtonCustom>
                <h4>
                  <FormattedMessage id="partials.privacypolicy.item4.title" />
                </h4>
              </AccordionItemButtonCustom>
            </AccordionItemHeadingCustom>
            <AccordionItemPanelCustom>
              <p>
                <FormattedHTMLMessage id="partials.privacypolicy.item4.paragraph1" />
              </p>
              <h4>
                <FormattedMessage id="partials.privacypolicy.item4.part1.title" />
              </h4>
              <p>
                <FormattedMessage id="partials.privacypolicy.item4.part1.paragraph1" />
              </p>
              <ul>
                <li>
                  <FormattedMessage id="partials.privacypolicy.item4.part1.item1" />
                </li>
                <li>
                  <FormattedHTMLMessage id="partials.privacypolicy.item4.part1.item2" />
                </li>
              </ul>
            </AccordionItemPanelCustom>
          </AccordionItemCustom>
          <AccordionItemCustom>
            <AccordionItemHeadingCustom>
              <AccordionItemButtonCustom>
                <h4>
                  <FormattedMessage id="partials.privacypolicy.item5.title" />
                </h4>
              </AccordionItemButtonCustom>
            </AccordionItemHeadingCustom>
            <AccordionItemPanelCustom>
              <p>
                <FormattedHTMLMessage id="partials.privacypolicy.item5.paragraph1" />
              </p>
            </AccordionItemPanelCustom>
          </AccordionItemCustom>
          <AccordionItemCustom>
            <AccordionItemHeadingCustom>
              <AccordionItemButtonCustom>
                <h4>
                  <FormattedMessage id="partials.privacypolicy.item6.title" />
                </h4>
              </AccordionItemButtonCustom>
            </AccordionItemHeadingCustom>
            <AccordionItemPanelCustom>
              <p>
                <FormattedHTMLMessage id="partials.privacypolicy.item6.paragraph1" />
              </p>
            </AccordionItemPanelCustom>
          </AccordionItemCustom>
          <AccordionItemCustom>
            <AccordionItemHeadingCustom>
              <AccordionItemButtonCustom>
                <h4>
                  <FormattedMessage id="partials.privacypolicy.item7.title" />
                </h4>
              </AccordionItemButtonCustom>
            </AccordionItemHeadingCustom>
            <AccordionItemPanelCustom>
              <p>
                <FormattedMessage id="partials.privacypolicy.item7.paragraph1" />
              </p>
            </AccordionItemPanelCustom>
          </AccordionItemCustom>
          <AccordionItemCustom>
            <AccordionItemHeadingCustom>
              <AccordionItemButtonCustom>
                <h4>
                  <FormattedMessage id="partials.privacypolicy.item8.title" />
                </h4>
              </AccordionItemButtonCustom>
            </AccordionItemHeadingCustom>
            <AccordionItemPanelCustom>
              <p>
                <FormattedMessage id="partials.privacypolicy.item8.paragraph1" />
              </p>
            </AccordionItemPanelCustom>
          </AccordionItemCustom>
          <AccordionItemCustom>
            <AccordionItemHeadingCustom>
              <AccordionItemButtonCustom>
                <h4>
                  <FormattedMessage id="partials.privacypolicy.item9.title" />
                </h4>
              </AccordionItemButtonCustom>
            </AccordionItemHeadingCustom>
            <AccordionItemPanelCustom>
              <p>
                <FormattedMessage id="partials.privacypolicy.item9.paragraph1" />
              </p>
            </AccordionItemPanelCustom>
          </AccordionItemCustom>
        </Accordion>
      </>
    );
  }
}

export default PrivacyPolicy;
